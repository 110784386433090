export const testSelectorAttribute = 'data-ui-test';

/**
 * Adds a selector for ui-testing.
 *
 * Usage:
 * <Component {...addTestSelector('componentName')}
 *
 * @param {string} name
 * @returns {{'data-ui-test': *}}
 */
export const addTestSelector = (name?: string) =>
	name != null ? { [testSelectorAttribute]: name } : undefined;

export const makeTestId = (key: string) => {
	return key.replace(/\s+/g, '-').toLowerCase();
};
